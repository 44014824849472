import { Fragment, useState, useEffect } from "react";
import { Row, Col } from 'antd';
import {
  ResponsiveContainer,
  Legend,
  PieChart,
  Tooltip,
  Cell,
  Pie,
} from 'recharts';

const COLORS = ['#502CA8', '#FC6DAB', '#F9CB40', '#3DDC97', '#6290C3', '#00E5E8', '#7A89C2', '#2D3047', '#6D435A'];
const AGES_TITLE = {
  '18_20': '18 A 20 años',
  '21_25': '21 A 25 años',
  '26_29': '26 A 29 años',
  '30_35': '30 A 35 años',
  '36_39': '36 A 39 años',
  '40_45': '40 A 45 años',
  '46_49': '46 A 49 años',
  '50_55': '50 A 55 años',
  '56_59': '56 A 59 años',
  '60_65': '60 A 65 años',
  '66_69': '66 A 69 años',
  '70_75': '70 A 75 años',
}

const GENDER_TITLE = {
  'F': 'Femenino',
  'M': 'Masculino',
  null: 'Sin definir',
}

const GenderAge = () => {
  const [ages, setAges] = useState();
  const [gender, setGender] = useState();

  const fetchAges = () => {
    fetch('https://app.vivosalud.in/api/dashboard/ages.json', {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
    })
      .then(response => response.json())
      .then(response => {
        const newData = [];
        const data = response.dashboard;
        for (let item of data) {
          newData.push({
            name: GENDER_TITLE[item.gender],
            value: item.count
          })
        }
        setAges(newData);
      });
  }

  const fetchGender = () => {
    fetch('https://app.vivosalud.in/api/dashboard/gender.json', {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
    })
      .then(response => response.json())
      .then(response => {
        const newData = [];
        const data = response.dashboard[0];
        for (let item of Object.keys(data)) {
          newData.push({
            name: AGES_TITLE[item],
            value: data[item]
          })
        }
        setGender(newData)
      });
  }

  useEffect(() => {
    fetchAges();
    fetchGender();
  }, []);

  return (
    <Row gutter={[40, 40]} style={{ marginTop: 40 }}>
      <Col lg={12}>
        <div className="card-table">
          <h3>Distribución por sexo</h3>
          {
            ages && <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400}>
                <Legend verticalAlign="top" align="center" wrapperStyle={{ top: 25 }} />
                <Pie data={ages} dataKey="value" nameKey="name">
                  {ages.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          }
        </div>
      </Col>
      <Col lg={12}>
        <div className="card-table">
          <h3>Distribución por edad</h3>
          {
            gender && <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400}>
                <Legend verticalAlign="top" align="center" wrapperStyle={{ top: 25 }} />
                <Pie data={gender} dataKey="value" nameKey="name">
                  {gender.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          }
        </div>
      </Col>
    </Row>
  );
}

export default GenderAge;