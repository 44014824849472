const CardNumber = ({ number, label, klass }) => {
  return (
    <div className={`card-number ${klass}`}>
      <div className="number">
        {number}
      </div>
      <div className="label">
        {label}
      </div>
    </div>
  )
}

export default CardNumber;