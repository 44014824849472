import { Fragment, useState, useEffect } from "react";
import { Row, Col } from "antd";
import CardNumber from "./CardNumber";

const General = () => {
  const [data, setData] = useState({
    invitations: 0,
    users: 0,
    active_users: 0,
    inactive_users: 0,
    users_with_apps: 0
  });

  const fetchGeneral = () => {
    fetch('https://app.vivosalud.in/api/dashboard/general.json', {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
    })
      .then(response => response.json())
      .then(response => setData(response));
  }

  useEffect(() => {
    fetchGeneral();
  }, []);

  return (
    <Fragment>
      <h2>Datos generales</h2>
      <Row gutter={[20, 30]} >
        <Col lg={8} xs={12}>
          <CardNumber number={new Intl.NumberFormat('es-CL').format(data.invitations)} label="Usuarios invitados" klass="yellow" />
        </Col>
        <Col lg={8} xs={12}>
          <CardNumber number={new Intl.NumberFormat('es-CL').format(data.users)} label="Usuarios totales" klass="purple" />
        </Col>
        <Col lg={8} xs={12}>
          <CardNumber number={new Intl.NumberFormat('es-CL').format(data.active_users)} label="Usuarios activos" klass="cyan" />
        </Col>
        <Col lg={8} xs={12}>
          <CardNumber number={new Intl.NumberFormat('es-CL').format(data.users - data.active_users)} label="Usuarios inactivos" klass="red" />
        </Col>
        <Col lg={8} xs={12}>
          <CardNumber number={Intl.NumberFormat('es-CL').format(data.users_with_apps)} label="Usuarios conectados" klass="green" />
        </Col>
      </Row>
    </Fragment>
  )
}

export default General