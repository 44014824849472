import { Fragment, useState, useEffect } from "react";
import { Row, Col } from "antd";
import CardNumber from "./CardNumber";

const Engagement = () => {
  const [data, setData] = useState({
    challenge_accepted: 0,
    users_goal: 0,
    total_answers: 0,
    total_correct_answers: 0,
    total_wrong_answers: 0
  });
  const fetchData = () => {
    fetch('https://app.vivosalud.in/api/dashboard/engagement.json', {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
    })
      .then(response => response.json())
      .then(response => setData(response));
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Fragment>
      <h2 style={{ marginTop: 60 }}>Participacion</h2>
      <Row gutter={[30, 20]}>
        <Col lg={12} xs={24} md={24}>
          <Row gutter={[30, 20]}>
            <Col lg={24} xs={12}>
              <CardNumber number={Intl.NumberFormat('es-CL').format(data.challenge_accepted)} label="Participantes en desafíos" klass="purple" />
            </Col>
            <Col lg={12} xs={12}>
              <CardNumber number={Intl.NumberFormat('es-CL').format(data.users_goal)} label="Cumplieron la meta" klass="red" />
            </Col>
          </Row>
        </Col>
        <Col lg={12} xs={24} md={24}>
          <Row gutter={[30, 20]}>
            <Col lg={24} xs={12}>
              <CardNumber number={Intl.NumberFormat('es-CL').format(data.total_answers)} label="Participantes en trivias" klass="orange" />
            </Col>
            <Col lg={12} xs={12}>
              <CardNumber number={Intl.NumberFormat('es-CL').format(data.total_correct_answers)} label="Respuestas correctas" klass="red" />
            </Col>
            <Col lg={12} xs={12}>
              <CardNumber number={Intl.NumberFormat('es-CL').format(data.total_wrong_answers)} label="Respuestas incorrectas" klass="cyan" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Engagement;