import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import LoginPage from "../LoginPage/LoginPage";
import HomePage from "../HomePage/HomePage";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={localStorage.getItem('token') ? <HomePage /> : <LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export { App };
