import { useState } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Layout,
  Button
} from 'antd';
import { authenticate } from '../services/authentication';

const { Header, Content } = Layout;


const LoginPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    authenticate(values)
      .then(response => {
        localStorage.setItem('company', response.company);
        localStorage.setItem('logo', response.logo);
        localStorage.setItem('token', response.token);
        window.location.reload(false);
      })
      .finally(response => {
        setLoading(false);
      })
  }

  return (
    <Layout>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%', textAlign: 'center' }}>
        <img src="https://muvu.cl/static/logoMuvuNavbar-cd8722f96cb022453454a6692c6d07f6.svg" />
      </Header>
      <Content className="site-layout" style={{ marginTop: 64 }}>
        <Row align="middle" justify="center" style={{ height: 'calc(100vh - 80px)' }}>
          <Col xl={8} lg={10} xs={24} md={20}>
            <div className="form-wrapper">
              <Row align="middle" justify="center">
                <Col xl={20} lg={24} xs={24} md={20}>
                  <div className="form-section">
                    <h1>Ingreso Empresas</h1>
                    <h2>Dashboard Muvu</h2>
                    <Form form={form} onFinish={onFinish} layout="vertical" size="large">
                      <Form.Item label="Email" name="email">
                        <Input type="email" />
                      </Form.Item>
                      <Form.Item label="Contraseña" name="password">
                        <Input type="password" />
                      </Form.Item>
                      <Button loading={loading} htmlType="submit" block type="primary">
                        Ingresar
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default LoginPage;