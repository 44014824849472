import { Fragment, useState, useEffect } from "react";
import { Row, Col, Table } from "antd";
import CardNumber from "./CardNumber";

const Reedem = () => {
  const [data, setData] = useState({
    invitations: 0,
    users: 0,
    most: [],
    active_users: 0,
    inactive_users: 0,
    users_with_apps: 0
  });

  const fetchGeneral = () => {
    fetch('https://app.vivosalud.in/api/dashboard/reedems.json', {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
    })
      .then(response => response.json())
      .then(response => setData(response));
  }

  useEffect(() => {
    fetchGeneral();
  }, []);

  const columns = [
    {
      title: 'Premio',
      dataIndex: 'image',
      key: 'image',
      align: 'center',
      render: (text, _) => {
        return <img width={100} src={text} />
      }
    },
    {
      title: 'Premio',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
    },
    {
      title: 'Puntos requeridos',
      dataIndex: 'value',
      align: 'center',
      key: 'value',
      render: (text, _) => {
        return Intl.NumberFormat('es-CL').format(text)
      }
    },
    {
      title: 'Total de canjes',
      dataIndex: 'count',
      align: 'center',
      key: 'count',
    },
    {
      title: 'Cantidad de usuarios que canjearon el premio',
      dataIndex: 'users',
      align: 'center',
      key: 'users',
    },
  ];

  return (
    <Fragment>
      <h2 style={{ marginTop: 60 }}>Canjes</h2>
      <Row gutter={[20, 30]}>
        <Col lg={8} xs={12}>
          <CardNumber number={Intl.NumberFormat('es-CL').format(data.reedems)} label="Total de canjes realizados" klass="purple" />
        </Col>
        <Col lg={8} xs={12}>
          <CardNumber number={Intl.NumberFormat('es-CL').format(data.users)} label="Usuarios que han canjeado" klass="cyan" />
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          {
            data && (
              <div className="card-table" style={{ marginTop: 40 }}>
                <h3 style={{ marginBottom: 20 }}>Premios mas canjeados</h3>
                <div style={{ overflowX: 'auto' }}>
                  <Table columns={columns} dataSource={data.most.filter(item => item.count > 0)} pagination={false} />
                </div>
              </div>
            )
          }
        </Col>
      </Row>
    </Fragment>
  )
}

export default Reedem;