import { Fragment, useState, useEffect } from "react";
import { Row, Col, Table, Empty } from "antd";
import CardNumber from "./CardNumber";

const Team = () => {
  const [data, setData] = useState({
    teams: 0,
    users: 0,
    summary_team: 0
  });

  const fetchGeneral = () => {
    fetch('https://app.vivosalud.in/api/dashboard/teams.json', {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      },
    })
      .then(response => response.json())
      .then(response => {
        const summary = [];
        Object.keys(response.summary_team).forEach(team => {
          summary.push({
            key: team,
            name: team,
            total: response.summary_team[team]
          })
        })
        console.log(summary);
        response['summary_team'] = summary;
        setData(response);
      });
  }

  useEffect(() => {
    fetchGeneral();
  }, []);

  const columns = [
    {
      title: 'Equipo',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
    },
    {
      title: 'Cantidad de integrantes',
      dataIndex: 'total',
      align: 'center',
      key: 'total',
    },
  ];

  return (
    <Fragment>
      {
        data && data.teams
          ? (
            <Fragment>
              <h2 style={{ marginTop: 60 }}>Equipos</h2>
              <Row gutter={[20, 30]}>
                <Col lg={8} xs={12}>
                  <CardNumber number={data.teams} label="Cantidad de equipos" klass="purple" />
                </Col>
                <Col lg={8} xs={12}>
                  <CardNumber number={data.users} label="Usuarios en  equipos" klass="cyan" />
                </Col>
                <Col lg={24} xl={14}>
                  <div className="card-table">
                    <h3 style={{ marginBottom: 20 }}>Usuarios por equipos</h3>
                    <Table columns={columns} dataSource={data.summary_team} pagination={false} />
                  </div>
                </Col>
              </Row>
            </Fragment>
          )
          : (
            <Fragment></Fragment>
          )
      }
    </Fragment>
  )
}

export default Team;