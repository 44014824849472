import {
  Row,
  Col,
  Layout,
  Button
} from 'antd';
import Team from './components/Team';
import Engagement from './components/Engagement';
import Reedem from './components/Reedem';
import General from './components/General';
import Categories from './components/Categories';
import GenderAge from './components/GenderAge';

const { Header, Content } = Layout;

const HomePage = () => {
  const logout = () => {
    localStorage.removeItem('company');
    localStorage.removeItem('logo');
    localStorage.removeItem('token');
    window.location.reload(false);
  }

  return (
    <Layout>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%', textAlign: 'center' }}>
        <img src="https://muvu.cl/static/logoMuvuNavbar-cd8722f96cb022453454a6692c6d07f6.svg" />
        <div style={{ float: 'right' }}>
          <Button type="link" onClick={logout}>
            Salir
          </Button>
        </div>
      </Header>
      <Content className="home-layout" style={{ marginTop: 64, paddingTop: 20, paddingBottom: 80, minHeight: 'calc(100vh - 60px)' }}>
        <Row justify="center">
          <Col lg={16}>
            <h1>
              Dashboard {localStorage.getItem('company')}
            </h1>
          </Col>
          <Col lg={16}>
            <div>
              <General />
            </div>
            <div>
              <GenderAge />
            </div>
            <div>
              <Reedem />
            </div>
            <div>
              <Engagement />
            </div>
            <div>
              <Team />
            </div>
            <div>
              <Categories />
            </div>
          </Col>
        </Row>
      </Content >
    </Layout >
  )
}

export default HomePage;